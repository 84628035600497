/* Text */

.text--purple {
  color: #5952c1;
}

.text--pink {
  color: #ff5376;
}

.text--green {
  color: #29b49c;
}

.text--success {
  color: #28a745;
}

.text--white {
  color: #fff;
}

.text--small {
  font-size: 0.8125em;
  line-height: 1.5em;
}

.text--caption {
  font-size: 0.875em;
  color: #767676;
}

.text--discrete {
  font-size: 0.75em;
  color: #b3b3b3;
}

/* Badges */

.badge-green {
  background-color: #29b49c;
}

.badge-grey {
  background-color: #d8d8d8;
}

.badge--white-text {
  color: #fff;
}

/* Buttons */

.btn {
  padding: 0.75em 1em;
  display: inline-block;
  border: none;
  color: #fff;
  font-size: 1.125em;
  font-weight: bold;
}

.btn:focus {
  outline: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn:hover {
  color: #fff;
}

.btn--large {
  padding: 0.875em 1.5em;
  font-size: 1.125em;
}

.btn--primary {
  background-color: #29b49c;
}

.btn--secondary {
  background-color: #5952c1;
}

.btn--pink {
  background-color: #ff5376;
}

.btn--rounded {
  border-radius: 50px;
}

.btn--block {
  width: 100%;
  display: block;
}

.btn--drop-shadow {
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
}

/* Form elements */

.large-field {
  min-width: 320px;
}

/* Background */

.background--grey {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Underlined field */

.underlined-field {
  width: 100%;
  padding: 0.625em;
  border: none;
  border-bottom: 1px solid #979797;
  font-size: 1.25em;
  background-color: transparent;
  transition: all 0.2s;
}

.underlined-field:focus {
  outline: none;
  border-color: #5952c1;
}

/* HERO */

.hero {
  background-color: rgba(0, 0, 0, 0.7);
  background-image: url("./images/hero-image.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

.hero--full {
  min-height: 80vh;
}

.hero__inner--bottom {
  padding: 8em 0 4em 0;
}

.hero--full .hero__inner {
  min-height: 70vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.hero__headline,
.hero__subline {
  color: #fff;
}

.hero__headline {
  font-size: 1.75em;
  font-weight: bold;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.hero__subline {
  font-size: 1.25em;
  font-weight: 500;
}

.hero__headline--large {
  font-size: 3em;
}

.hero__overlap {
  margin-top: -3em;
  padding: 1.25em;
  background-color: #fff;
}

@media (min-width: 768px) {
  .hero__headline {
    font-size: 2em;
  }
  .hero__headline--large {
    font-size: 4em;
  }
}

/* HEADER */

.simple-header {
  width: 100%;
  padding: 0.625em 0;
  background-color: rgba(89, 82, 193, 0.8);
}

.simple-header--transparent {
  background: transparent;
}

.simple-header--floating {
  position: absolute;
}

.simple-header__logo {
  width: 5.5em;
}

.simple-header__logo img {
  width: 100%;
}

.simple-header__account {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.simple-header__account-label {
  margin-right: 0.5em;
  color: #fff;
}

.simple-header__account-avatar {
  width: 1.875em;
  height: 1.875em;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  font-weight: bold;
  color: #29b49c;
  background-color: #fff;
}

/* FOOTER */

.app-footer__copyright {
  font-size: 0.75em;
}

.app-footer__list {
  padding: 0;
  display: flex;
  list-style: none;
  font-size: 0.75em;
}

.app-footer__list-item {
  margin: 0 0.3125em;
}
