.progress-steps {
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.progress-steps__step {
  min-width: 1.5em;
  height: 1.5em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
}

.progress-steps__step.is-done {
  background-color: #5952c1;
}

.progress-steps__step::before {
  content: attr(data-step);
  color: #fff;
}

.progress-steps__step--first {
  border: 5px solid #d8d8d8;
  background-color: transparent;
}

.progress-steps__step--first.is-done {
  background-color: transparent;
  border-color: #5952c1;
}

.progress-steps__step--first::before {
  content: "";
}

.progress-steps__bar {
  width: 100%;
  height: 0.3125em;
  background-color: #d8d8d8;
}

.progress-steps__bar-percentage {
  width: 0;
  height: 100%;
  background-color: #5952c1;
}
