.survey-header {
  padding: 1.25em 0;
  position: relative;
}

.survey-header__prev-button {
  position: absolute;
  top: 3.4em;
  left: 0.25em;
  font-weight: bold;
  font-size: 1.25em;
  line-height: 0;
  color: #5952c1;
  cursor: pointer;
}
