.choices {
  width: 100%;
}

.choices__row--two,
.choices__row--three {
  margin: 0 -0.3125em;
  display: flex;
  flex-wrap: wrap;
}

.choices__col {
  width: 100%;
}

.choices__row--two .choices__col,
.choices__row--three .choices__col {
  padding: 0 0.3125em;
}

.choices__row--two .choices__col {
  width: 50%;
}

.choices__row--three .choices__col {
  width: 33.3333%;
}

.choice__button {
  width: 100%;
  padding: 0.875em;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
  text-align: center;
  background-color: #efefef;
  cursor: pointer;
}

.choice__button:hover {
  background-color: #ececec;
}

.choice__button.is-chosen {
  background-color: #5952c1;
  color: #fff;
}

.choice__button--image {
  border: 3px solid #d8d8d8;
  background-color: #fff;
}

.choice__button--image.is-chosen {
  background-color: #fff;
  border-color: #5952c1;
  color: inherit;
}

.choice__button-title {
  font-size: 1em;
}

.choice__button-image {
  width: 100%;
  margin-bottom: 1.25em;
}

@media (max-width: 375px) {
  .choice__button--image .choice__button-title {
    font-size: 0.75em;
  }
}
