.autocompletion {
  background-color: #fff;
}

.autocompletion__suggestion,
.autocompletion__suggestion--active {
  padding: 0.625em 0;
  cursor: pointer;
}

.autocompletion .autocompletion__suggestion:first-child,
.autocompletion__suggestion--active:first-child {
  padding-top: 1.25em;
}

.autocompletion__suggestion:hover,
.autocompletion__suggestion--active:hover {
  color: #5952c1;
}
